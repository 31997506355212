<template>
	<v-container class="c-fluid course-editor-header secondary-background">
		<v-row class="centered">
			<v-col cols="3">
				<v-row class="s">
					<v-col cols="12 px-0 pb-0">
						<div
							v-if="!isCourseNameEdited"
							class="d-flex flex-row align-center"
						>
							<h4 class="mb-0">{{ courseName }}</h4>

							<v-icon
								class="ml-4"
								@click="setIsCourseNameEdited(true)"
								>mdi-pencil-outline</v-icon
							>
						</div>
						<div v-else class="d-flex flex-row align-center">
							<InputTextField
								class="ma-0"
								v-model="newCourseName"
							>
								<template #label>Course name</template>
							</InputTextField>
							<v-icon class="ml-4" @click="saveCourseName"
								>mdi-check-outline</v-icon
							>
							<v-icon class="ml-4" @click="cancelEditing"
								>mdi-close</v-icon
							>
						</div>
					</v-col>
					<v-col cols="12" class="py-0 px-0 course-meta">
						<span class="text-muted">Status</span> <b>{{ status }}</b> <span class="text-muted ml-4">Saved
							{{ saveTime }} day<span v-if="saveTime > 1">s</span> ago</span></small
						>

						</v-col
					>

				</v-row>
			</v-col>


			<v-col cols="9" class="text-right">
				<backTo :link="'/admin/courses'"
					><template #text><span><b>Back to courses</b></span></template></backTo
				>
				<span class="ml-9 mr-9 clickable settings-button" @click="goCourseSettings"
					>Course Settings</span
				>
				<v-btn
					v-if="course.published_at === null"
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					@click="publishCourse"
					>Publish</v-btn
				>
				<v-btn
					v-else
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					@click="unpublishCourse"
					>Unpublish</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import InputTextField from "@/components/InputTextField.vue";
import BackTo from "@/components/BackTo.vue";
import { put } from "@/util/requests/put";
import { get } from "@/util/requests/get";
import { mapGetters } from "vuex";

export default {
	name: "CourseEditorHeader",
	components: { InputTextField, BackTo },
	props: {
		courseName: {
			type: String,
			require: true
		},
		courseId: {
			type: Number,
			require: true
		},
		course: {
			type: Object,
			require: true
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		status() {
			return this.course.published_at === null ? "Draft" : "Published";
		},
		saveTime() {
			return Math.ceil(
				Number(new Date() - new Date(this.course.updated_at)) /
					1000 /
					60 /
					60 /
					24
			);
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			isCourseNameEdited: false,
			newCourseName: ""
		};
	},
	methods: {
		goCourseSettings() {
			this.$router.push({
				name: "CourseSettings",
				id: this.$route.params.id
			});
		},
		setIsCourseNameEdited(val) {
			this.isCourseNameEdited = val;
		},
		async saveCourseName() {
			await this.$store.dispatch("setIsLoading", true);
			await put("/course", this.courseId, {
				title: this.newCourseName,
				description: ""
			});
			await this.$emit("getCourse");
			await this.cancelEditing();
			await this.$store.dispatch("setIsLoading", false);
		},
		async cancelEditing() {
			this.setIsCourseNameEdited(false);
			this.newCourseName = "";
		},
		publishCourse() {
			this.$router.push({
				name: "CoursePublish",
				params: {
					id: this.$route.params.id
				}
			});
		},
		async unpublishCourse() {
			await this.$store.dispatch("setIsLoading", true);
			try {
				const response = await get(
					`/course_unpublish/${this.$route.params.id}`
				);
				if (response.status === 200) {
					await this.$emit("getCourse");
				}
			} catch (error) {
				this.$emit("setMessage", error.response.data.message);
			}

			await this.$store.dispatch("setIsLoading", false);
		}
	}
};
</script>

<style lang="scss" scoped>
.c-fluid {
	max-width: 100% !important;
}

.back-button span,
.settings-button {
	text-transform: lowercase;
	text-decoration: underline;
	color: #2F91AE !important;
	font-weight: 500;
}

.course-meta {
	font-size: 14px;
}
.course-meta b {
	font-size: 14px;
}
</style>
